<template>
  <div>
    <b-modal
      id="hapus"
      cancel-variant="outline-secondary"
      ok-title="Hapus"
      cancel-title="Close"
      centered
      title="Hapus Produk"
      @ok="onDelete"
    >
      <b-form>
        <b-overlay :show="isLoadingAction" rounded="sm">
          <b-form-group>
            <p>Apakah anda yakin ingin menghapus produk ini?</p>
          </b-form-group>
        </b-overlay>
      </b-form>
    </b-modal>

    <div
      class="px-2 py-1 text-white rounded bg-primary tw-font-semibold tw-text-2xl"
    >
      Daftar Video
    </div>
    <div class="my-2">
      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-end">
        <template v-if="!isLoading">
          <label for="show">{{ toggle.video ? '' : 'Tidak ' }}Aktif</label>
          <b-form-checkbox
            v-model="toggle.video"
            id="toggle-video"
            :checked="toggle.video"
            switch
            @change="onChangeToggle('video')"
          />
        </template>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.tambah-video
          variant="primary"
        >
          <feather-icon class="mr-1" icon="PlusCircleIcon" />
          <span class="text-nowrap">Tambah Video</span>
        </b-button>
      </div>
    </div>
    <b-card no-body class="mb-2">
      <b-modal
        id="tambah-video"
        cancel-variant="outline-secondary"
        ok-title="Tambah"
        cancel-title="Cancel"
        centered
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        title="Tambah Video"
        @ok="onSubmit"
      >
        <b-form>
          <b-form-group>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :label="video.name ? 'name' : 'nama'"
              :options="listVideo"
              :reduce="(video) => video.nama || video.name"
              @option:selected="onChangeProduct"
              multiple
            />
          </b-form-group>
        </b-form>
      </b-modal>

      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="video"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(is_active)="data">
          {{ !!data.value ? "Aktif" : "Non-aktif" }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="setTargetedId(data.item.product_bundle_id)"
              v-b-modal.hapus
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <div
      class="px-2 py-1 text-white rounded bg-primary tw-font-semibold tw-text-2xl"
    >
      Daftar Ebook
    </div>
    <div class="my-2">
      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-end">
        <template v-if="!isLoading">
          <label for="show">{{ toggle.ebook ? '' : 'Tidak ' }}Aktif</label>
          <b-form-checkbox
            v-model="toggle.ebook"
            id="toggle-ebook"
            :checked="toggle.ebook"
            switch
            @change="onChangeToggle('ebook')"
          />
        </template>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.tambah-ebook
          variant="primary"
        >
          <feather-icon class="mr-1" icon="PlusCircleIcon" />
          <span class="text-nowrap">Tambah Ebook</span>
        </b-button>
      </div>
    </div>
    <b-card no-body class="mb-2">
      <b-modal
        id="tambah-ebook"
        cancel-variant="outline-secondary"
        ok-title="Tambah"
        cancel-title="Cancel"
        centered
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        title="Tambah Ebook"
        @ok="onSubmit"
      >
        <b-form>
          <b-form-group>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :label="ebook.name ? 'name' : 'nama'"
              :options="listEbook"
              :reduce="(ebook) => ebook.nama || ebook.name"
              @option:selected="onChangeProduct"
              multiple
            />
          </b-form-group>
        </b-form>
      </b-modal>

      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="ebook"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(is_active)="data">
          {{ !!data.value ? "Aktif" : "Non-aktif" }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="setTargetedId(data.item.product_bundle_id)"
              v-b-modal.hapus
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <div
      class="px-2 py-1 text-white rounded bg-primary tw-font-semibold tw-text-2xl"
    >
      Daftar Tryout
    </div>
    <div class="my-2">
      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-end">
        <template v-if="!isLoading">
          <label for="show">{{ toggle.tryout ? '' : 'Tidak ' }}Aktif</label>
          <b-form-checkbox
            v-model="toggle.tryout"
            id="toggle-tryout"
            :checked="toggle.tryout"
            switch
            @change="onChangeToggle('tryout')"
          />
        </template>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.tambah-tryout
          variant="primary"
        >
          <feather-icon class="mr-1" icon="PlusCircleIcon" />
          <span class="text-nowrap">Tambah Tryout</span>
        </b-button>
      </div>
    </div>
    <b-card no-body class="mb-2">
      <b-modal
        id="tambah-tryout"
        cancel-variant="outline-secondary"
        ok-title="Tambah"
        cancel-title="Cancel"
        centered
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        title="Tambah Tryout"
        @ok="onSubmit"
      >
        <b-form>
          <b-form-group>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="listTryout"
              :reduce="(tryout) => tryout.nama || tryout.name"
              @option:selected="onChangeProduct"
              multiple
            />
          </b-form-group>
        </b-form>
      </b-modal>

      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="tryout"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(is_active)="data">
          {{ !!data.value ? "Aktif" : "Non-aktif" }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="setTargetedId(data.item.product_bundle_id)"
              v-b-modal.hapus
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <div
      class="px-2 py-1 text-white rounded bg-primary tw-font-semibold tw-text-2xl"
    >
      Daftar Komunitas
    </div>
    <div class="my-2">
      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-end">
        <template v-if="!isLoading">
          <label for="show">{{ toggle.community ? '' : 'Tidak ' }}Aktif</label>
          <b-form-checkbox
            v-model="toggle.community"
            id="toggle-community"
            :checked="toggle.community"
            switch
            @change="onChangeToggle('community')"
          />
        </template>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.tambah-komunitas
          variant="primary"
        >
          <feather-icon class="mr-1" icon="PlusCircleIcon" />
          <span class="text-nowrap">Tambah Komunitas</span>
        </b-button>
      </div>
    </div>
    <b-card no-body class="mb-2">
      <b-modal
        id="tambah-komunitas"
        cancel-variant="outline-secondary"
        ok-title="Tambah"
        cancel-title="Cancel"
        centered
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        title="Tambah Komunitas"
        @ok="onSubmit"
      >
        <b-form>
          <b-form-group>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="nama"
              :options="listKomunitas"
              :reduce="(komunitas) => komunitas.nama || komunitas.name"
              @option:selected="onChangeProduct"
              multiple
            />
          </b-form-group>
        </b-form>
      </b-modal>

      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="komunitas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(is_active)="data">
          {{ !!data.value ? "Aktif" : "Non-aktif" }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="setTargetedId(data.item.product_bundle_id)"
              v-b-modal.hapus
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BCardText,
  BButton,
  BTable,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
// import store from "@/store";
import { ref, onMounted, watch } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

// API
import repository from "@repofactory";
const repoBundles = repository.get("BundleRepository");
const repoProducts = repository.get("ProductsRepository")

// Routing
import { useRouter, rupiah } from "@core/utils/utils";

export default {
  components: {
    // ModalTambah,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormCheckbox,
    VBModal,
    BForm,
    BFormGroup,
    BCardText,
    BDropdown,
    flatPickr,
    BDropdownItem,
    ToastificationContent,
    BCardActions,
    draggable,
    BListGroupItem,
    vSelect,
    BOverlay,
    DatePicker,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast();
    const { route } = useRouter();

    const toggle = ref({
      tryout: false,
      video: false,
      ebook: false,
      community: false,
    });

    const tryout = ref([])
    const video = ref([])
    const ebook = ref([])
    const komunitas = ref([])

    const listVideo = ref([])
    const listEbook = ref([])
    const listTryout = ref([])
    const listKomunitas = ref([])

    const selectedProduct = ref([])

    const isLoading = ref(false);
    const isLoadingAction = ref(false);
    const targetedId = ref(null);

    const tableColumns = [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "name",
        label: "Nama Product",
      },
      {
        key: "label",
        label: "Label",
      },
      {
        key: "harga",
        label: "Harga",
        formatter: (value) => {
          return rupiah(value)
        },
      },
      {
        key: "harga_diskon",
        label: "Diskon",
        formatter: (value) => {
          return rupiah(value)
        },
      },
      {
        key: "is_active",
        label: "Status"
      },
      {
        key: "actions",
      },
    ];

    const setTargetedId = (value) => {
      targetedId.value = value;
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const fetchDetailBundle = async () => {
      try {
        isLoading.value = true
        const res = await repoBundles.getDetail(route.value.params.id);
        const products = res.data.data.items

        toggle.value.video = res.data.data.toggle_video
        toggle.value.ebook = res.data.data.toggle_ebook
        toggle.value.tryout = res.data.data.toggle_tryout
        toggle.value.community = res.data.data.toggle_community

        video.value = []
        ebook.value = []
        tryout.value = []
        komunitas.value = []
        if(!!products.length) {
          products.forEach(item => {
            if(item.product_type === "video") {
              video.value = [...video.value, {
                ...item,
                name: item.nama || item.name
              }]
            } else if(item.product_type === "ebook") {
              ebook.value = [...ebook.value, {
                ...item,
                name: item.nama || item.name
              }]
            } else if(item.product_type === "paket_tryout") {
              tryout.value = [...tryout.value, {
                ...item,
                name: item.nama || item.name
              }]
            } else if(item.product_type === "community") {
              komunitas.value = [...komunitas.value, {
                ...item,
                name: item.nama || item.name
              }]
            }
          })
        }

      } catch (e) {
        if (e.response.data.message) {
          showToast(
            "Error",
            e.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast(
            "Error",
            "Error fetching data bundle detail",
            "danger",
            "AlertTriangleIcon"
          );
        }
      } finally {
        isLoading.value = false
      }
    };

    const fetchVideo = async () => {
      try {
        const res = await repoProducts.get("video")
        listVideo.value = res.data.data
      } catch (e) {
        if(e.response.data.message) {
          showToast(
          "Error",
          e.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
        } else {
          showToast(
          "Error",
          "Error Fetching data video",
          "danger",
          "AlertTriangleIcon"
        );
        }
      }
    }

    const fetchEbook = async () => {
      try {
        const res = await repoProducts.get("ebook")
        listEbook.value = res.data.data
      } catch (e) {
        if(e.response.data.message) {
          showToast(
          "Error",
          e.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
        } else {
          showToast(
          "Error",
          "Error Fetching data ebook",
          "danger",
          "AlertTriangleIcon"
        );
        }
      }
    }

    const fetchTryout = async () => {
      try {
        const res = await repoProducts.get("paket_tryout")
        listTryout.value = res.data.data
      } catch (e) {
        if(e.response.data.message) {
          showToast(
          "Error",
          e.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
        } else {
          showToast(
          "Error",
          "Error Fetching data ebook",
          "danger",
          "AlertTriangleIcon"
        );
        }
      }
    }

    const fetchKomunitas = async () => {
      try {
        const res = await repoProducts.get("community")
        listKomunitas.value = res.data.data
      } catch (e) {
        if(e.response.data.message) {
          showToast(
          "Error",
          e.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
        } else {
          showToast(
          "Error",
          "Error Fetching data komunitas",
          "danger",
          "AlertTriangleIcon"
        );
        }
      }
    }

    const onChangeProduct = (value) => {
      console.log('value', value)
      selectedProduct.value = value
    }

    const onSubmit = async () => {
      try {
        const data = {
          bundle_id: route.value.params.id,
          products: selectedProduct.value.map(item => ({
            product_id: item.id,
            product_type: item.product_type
          }))
        }
        const res = await repoBundles.createProductBundle(data)
        showToast("Notifikasi", res.data.message, "primary");
        fetchDetailBundle()
      } catch (e) {
        if(e.response.data.message) {
          showToast(
          "Error",
          e.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
        } else {
          showToast(
          "Error",
          "Error submit data to bundle",
          "danger",
          "AlertTriangleIcon"
        );
        }
      }
    }

    const onDelete = async () => {
      try {
        isLoadingAction.value = true
        const res = await repoBundles.deleteProductBundle(targetedId.value)
        showToast("Notifikasi", res.data.message, "primary");
        fetchDetailBundle()
      } catch (e) {
        if(e.response.data.message) {
          showToast(
          "Error",
          e.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
        } else {
          showToast(
          "Error",
          "Error submit data to bundle",
          "danger",
          "AlertTriangleIcon"
        );
        }
      } finally {
        isLoadingAction.value = false
      }
    }

    const onChangeToggle = async (value) => {
      try {
        const payload = {
          "status": toggle.value[value],
          "toggle": `toggle_${value}`
        }

        const res = await repoBundles.updateToggle(route.value.params.id, payload);
        if (res.status === 200) {
          showToast("Notifikasi", `Successfully updated toggle ${value}.`, "primary");
        }
      } catch (e) {
        toggle.value[value] = !toggle.value[value]

        showToast(
          "Error",
          `Error update toggle ${value}.`,
          "danger",
          "AlertTriangleIcon"
        );
      }
    }

    onMounted(() => {
      fetchDetailBundle()
      fetchVideo()
      fetchEbook()
      fetchTryout()
      fetchKomunitas()
    })



    return {
      tableColumns,
      isLoading,
      toggle,
      tryout,
      video,
      ebook,
      komunitas,
      listVideo,
      listEbook,
      listTryout,
      listKomunitas,
      isLoadingAction,

      setTargetedId,
      onChangeProduct,
      onSubmit,
      rupiah,
      onDelete,
      onChangeToggle
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
